export const scrollToSection = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.pageYOffset;
    const elementHeight = elementRect.height;
    const middle = absoluteElementTop - (window.innerHeight / 2) + (elementHeight / 2);
    const offset = 50; // Adjust this value to fine-tune the scroll position
    window.scrollTo({
      top: middle - offset,
      behavior: "smooth",
    });
  }
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
