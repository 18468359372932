import React from "react";
import { motion } from "framer-motion";
import iPhoneLeftScreenshotWorkout from "../assets/images/iPhoneLeftScreenshotWorkout.png";
import DownloadOnTheAppStore from "../assets/images/DownloadOnTheAppStore.svg";
import { scrollToSection } from "../utils/scrollUtils";

const HeroSection: React.FC = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5, delay: 0.2 }}
    className="w-full max-w-6xl flex flex-col items-center mb-8 sm:mb-12 md:mb-16 mt-0"
  >
    <div className="w-full flex flex-col md:flex-row items-center">
      <div className="w-full md:w-2/3 md:pr-8 lg:pr-12">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-4 text-flexerRed text-center sm:text-center md:text-left"
        >
          Get addicted to working out.
        </motion.h1>
        
        <motion.div
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="md:hidden w-1/2 sm:w-2/5 mx-auto mb-6"
        >
          <img
            src={iPhoneLeftScreenshotWorkout}
            alt="Flexer App Screenshot"
            className="w-full"
            loading="lazy"
          />
        </motion.div>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className="text-base sm:text-lg md:text-xl text-flexerDarkGray font-normal mb-4 text-center sm:text-center md:text-left"
        >
          Say goodbye to the notepad and complex spreadsheets. Flexer is a simple,
          yet powerful workout tracking app that makes the gym easier than ever
          before. Whether you consider yourself a newbie or a full-on gym bro,
          Flexer cuts through the noise and gets you closer to your goals.
        </motion.p>

        <motion.ul
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="text-base sm:text-lg md:text-xl text-flexerDarkGray font-normal list-disc pl-6 mb-4 text-left sm:text-center md:text-left"
        >
          <li>
            Generate personalized workout routines with{" "}
            <span
              className="underline cursor-pointer"
              onClick={() => scrollToSection("ai")}
            >
              Flexer's AI workout planner
            </span>
          </li>
          <li>
            Use Flexer's{" "}
            <span
              className="underline cursor-pointer"
              onClick={() => scrollToSection("workouts")}
            >
              simple interface
            </span>{" "}
            to log sets, reps, and weights for your lifts
          </li>
          <li>
            Create your own{" "}
            <span
              className="underline cursor-pointer"
              onClick={() => scrollToSection("routines")}
            >
              custom workouts
            </span>{" "}
            for future use
          </li>
          <li>
            Choose from{" "}
            <span
              className="underline cursor-pointer"
              onClick={() => scrollToSection("exercises")}
            >
              300+ exercises
            </span>{" "}
            with exercise form guides and images
          </li>
          <li>
            Track PRs and overall workout stats with{" "}
            <span
              className="underline cursor-pointer"
              onClick={() => scrollToSection("progress")}
            >
              charts, graphs, and more
            </span>
          </li>
          <li>
            Gamify your workout experience with{" "}
            <span
              className="underline cursor-pointer"
              onClick={() => scrollToSection("trophies")}
            >
              achievements and trophies
            </span>
          </li>
        </motion.ul>
        
        <div className="flex justify-center sm:justify-center md:justify-start">
          <motion.a
            href="https://apps.apple.com/us/app/flexer-workout-tracker/id6702026405"
            target="_blank"
            rel="noopener noreferrer"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.7 }}
            className="inline-block mt-4"
          >
            <motion.img
              src={DownloadOnTheAppStore}
              alt="Download on the App Store"
              className="h-16 sm:h-20 md:h-24 lg:h-28 w-auto"
              whileHover={{ scale: 1.05 }}
            />
          </motion.a>
        </div>
      </div>
      
      <motion.div
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="hidden md:block w-1/3"
      >
        <img
          src={iPhoneLeftScreenshotWorkout}
          alt="Flexer App Screenshot"
          className="w-full"
          loading="lazy"
        />
      </motion.div>
    </div>
  </motion.div>
);

export default HeroSection;