import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import FlexerTextLogo from "../assets/images/FlexerTextLogo.png";
import DownloadOnTheAppStore from "../assets/images/DownloadOnTheAppStore.svg";
import { scrollToSection, scrollToTop } from "../utils/scrollUtils";
import { features } from "../data/screenshots";

const Header: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const handleFeatureClick = (id: string) => {
    scrollToSection(id);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isRootPath = location.pathname === "/";

  return (
    <motion.header
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="flex justify-between items-center p-3 md:p-4 bg-white fixed top-0 left-0 right-0 z-50"
    >
      <Link to="/" className="flex items-center" onClick={scrollToTop}>
        <motion.img
          whileHover={{ scale: 1.07 }}
          src={FlexerTextLogo}
          alt="Flexer Logo"
          className="h-8 sm:h-9 md:h-11"
        />
      </Link>
      {isRootPath && (
        <div className="flex-grow flex justify-center">
          <div className="relative" ref={dropdownRef}>
            <motion.button
              whileHover={{ scale: 1.05, backgroundColor: "#f0f0f0" }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className={`px-6 py-2 rounded-full text-flexerRed font-semibold transition-all duration-300 ease-in-out ${
                isDropdownOpen ? "bg-flexerLightGray" : "bg-white"
              }`}
              style={{ width: "max-content", minWidth: "150" }}
            >
              <span className="mr-2 text-lg">Features</span>
              <motion.span
                animate={{ rotate: isDropdownOpen ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              >
                ▼
              </motion.span>
            </motion.button>
            <AnimatePresence>
              {isDropdownOpen && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                  className="absolute right-0 mt-2 w-auto bg-white rounded-lg shadow-lg py-2 z-10"
                  style={{ top: "100%" }}
                >
                  {features.map((feature, index) => (
                    <motion.button
                      key={feature.id}
                      onClick={() => handleFeatureClick(feature.id)}
                      className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-flexerLightGray transition-colors duration-200"
                      whileHover={{ x: 5 }}
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.2, delay: index * 0.05 }}
                    >
                      {feature.name}
                    </motion.button>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      )}
      <motion.a
        whileHover={{ scale: 1.07 }}
        href="https://apps.apple.com/us/app/flexer-workout-tracker/id6702026405"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={DownloadOnTheAppStore}
          alt="Download on the App Store"
          className="h-8 sm:h-9 md:h-11"
        />
      </motion.a>
    </motion.header>
  );
};

export default Header;
