import React from "react";
import { motion } from "framer-motion";
import { Feature } from "../data/screenshots";

interface FeatureItemProps {
  feature: Feature;
  index: number;
  isImageLeft: boolean;
}

const FeatureItem: React.FC<FeatureItemProps> = ({
  feature,
  index,
  isImageLeft,
}) => (
  <motion.div
    key={feature.id}
    id={feature.id}
    initial={{ opacity: 0, y: 50 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true, amount: 0.2 }}
    transition={{ duration: 0.5, delay: index * 0.1 }}
    className={`screenshot bg-flexerLightGray rounded-3xl py-6 sm:py-8 px-6 sm:px-10 md:px-16 flex flex-col items-center ${
      isImageLeft ? "md:flex-row" : "md:flex-row-reverse"
    } md:items-center`}
  >
    <motion.img
      whileHover={{ scale: 1.10 }}
      src={feature.src}
      className={`w-1/2 sm:w-1/3 md:w-1/4 mb-6 sm:mb-8 md:mb-0 ${
        isImageLeft ? "md:mr-8" : "md:ml-8"
      }`}
      loading="lazy"
    />
    <div className="text-center md:text-left w-full">
      <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-3 sm:mb-4">
        {feature.title}
      </h2>
      <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-normal text-flexerDarkGray">
        {feature.description}
      </p>
    </div>
  </motion.div>
);

export default FeatureItem;
