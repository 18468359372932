import React from "react";
import { features } from "../data/screenshots";
import FeatureItem from "./FeatureItem";

const FeatureSection: React.FC = () => (
  <div className="w-full space-y-8 sm:space-y-12 px-4 sm:px-8 md:px-16">
    {features.map((feature, index) => (
      <FeatureItem
        key={feature.id}
        feature={feature}
        index={index}
        isImageLeft={index % 2 === 0}
      />
    ))}
  </div>
);

export default FeatureSection;