import iPhonePortraitScreenshotWorkout from "../assets/images/iPhonePortraitScreenshotWorkout.png";
import iPhonePortraitScreenshotTrophies from "../assets/images/iPhonePortraitScreenshotTrophies.png";
import iPhonePortraitScreenshotRoutines from "../assets/images/iPhonePortraitScreenshotRoutines.png";
import iPhonePortraitScreenshotProgress from "../assets/images/iPhonePortraitScreenshotProgress.png";
import iPhonePortraitScreenshotExercises from "../assets/images/iPhonePortraitScreenshotExercises.png";
import iPhonePortraitScreenshotAI from "../assets/images/iPhonePortraitScreenshotAI.png";

export interface Feature {
  id: string;
  name: string;
  title: string;
  description: string;
  src: string;
}

export const features: Feature[] = [
  {
    id: "ai",
    name: "AI-Generated Workout Plans",
    title: "AI-powered workout planning.",
    description:
      "Let Flexer's AI create personalized workout plans tailored to your goals, fitness level, and available equipment. Get expert guidance at your fingertips.",
    src: iPhonePortraitScreenshotAI,
  },
  {
    id: "workouts",
    name: "Beginner-Friendly Design",
    title: "Workouts made easy.",
    description:
      "Track your workouts with ease using our intuitive interface. Set goals, log exercises, and monitor your progress all in one place.",
    src: iPhonePortraitScreenshotWorkout,
  },
  {
    id: "exercises",
    name: "300+ Exercises",
    title: "Every exercise you need.",
    description:
      "Access a comprehensive library of exercises with detailed instructions and image demonstrations. Learn proper form and technique for each movement.",
    src: iPhonePortraitScreenshotExercises,
  },
  {
    id: "progress",
    name: "PR Tracking",
    title: "Track your progress.",
    description:
      "Visualize your progress over time with a variety of detailed charts and graphs. Track improvements in strength, endurance, and overall fitness.",
    src: iPhonePortraitScreenshotProgress,
  },
  {
    id: "routines",
    name: "Custom Workout Routines",
    title: "Never forget a routine.",
    description:
      "Create and customize routines tailored to your specific goals. Set your weekly workout split and never miss a day.",
    src: iPhonePortraitScreenshotRoutines,
  },
  {
    id: "trophies",
    name: "Game-Based Progress Tracking",
    title: "Turn the gym into a game.",
    description:
      "Earn trophies and achievements as you reach milestones in your fitness journey. Stay motivated and celebrate your progress.",
    src: iPhonePortraitScreenshotTrophies,
  },
];
