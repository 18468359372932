import React from "react";
import { motion } from "framer-motion";
import HeroSection from "./HeroSection";
import FeatureSection from "./FeatureSection";
import DownloadOnTheAppStore from "../assets/images/DownloadOnTheAppStore.svg";

const MainContent: React.FC = () => (
  <main className="flex-grow flex flex-col justify-start items-center p-4 sm:p-8 md:p-12 mt-20">
    <HeroSection />
    <FeatureSection />
    <motion.a
      whileHover={{ scale: 1.07 }}
      href="https://apps.apple.com/us/app/flexer-workout-tracker/id6702026405"
      target="_blank"
      rel="noopener noreferrer"
      className="mt-8"
    >
      <img
        src={DownloadOnTheAppStore}
        alt="Download on the App Store"
        className="h-16 sm:h-20 md:h-24 lg:h-28 w-auto"
      />
    </motion.a>
  </main>
);

export default MainContent;