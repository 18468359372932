import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./assets/styles/index.css";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCrS7jCFPDyHQuDCjGIWJJEbig-PBlGGg8",
  authDomain: "flexwebsite-38e6a.firebaseapp.com",
  projectId: "flexwebsite-38e6a",
  storageBucket: "flexwebsite-38e6a.appspot.com",
  messagingSenderId: "386274003338",
  appId: "1:386274003338:web:f211b7100ca8b3c505e073",
  measurementId: "G-CW5S8DSGE8",
};

initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
