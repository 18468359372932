import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <main className="flex-grow flex flex-col justify-start items-center p-4 sm:p-8 md:p-12 mt-20">
      <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-6 text-flexerRed">
        Privacy Policy
      </h1>
      <div className="max-w-3xl text-base sm:text-lg md:text-xl text-flexerDarkGray font-normal">
        <p className="mb-4">
          This privacy policy applies to the Flexer app (hereby referred to as
          "Application") for mobile devices that was created by Dillon Trembath
          (hereby referred to as "Service Provider") as a Free service. This
          service is intended for use "AS IS".
        </p>
        <h2 className="text-2xl sm:text-3xl font-bold mt-6 mb-4">
          Information Collection and Use
        </h2>
        <p className="mb-4">
          The Application collects information when you download and use it.
          This information may include information such as:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>Your device's Internet Protocol address (e.g. IP address)</li>
          <li>
            The pages of the Application that you visit, the time and date of
            your visit, the time spent on those pages
          </li>
          <li>The time spent on the Application</li>
          <li>The operating system you use on your mobile device</li>
        </ul>
        <p className="mb-4">
          The Application does not gather precise information about the location
          of your mobile device.
        </p>
        <p className="mb-4">
          The Service Provider may use the information you provided to contact
          you from time to time to provide you with important information,
          required notices and marketing promotions.
        </p>
        <p className="mb-4">
          For a better experience, while using the Application, the Service
          Provider may require you to provide us with certain personally
          identifiable information, including but not limited to email. The
          information that the Service Provider request will be retained by them
          and used as described in this privacy policy.
        </p>
        <h2 className="text-2xl sm:text-3xl font-bold mt-6 mb-4">
          Third Party Access
        </h2>
        <p className="mb-4">
          Only aggregated, anonymized data is periodically transmitted to
          external services to aid the Service Provider in improving the
          Application and their service. The Service Provider may share your
          information with third parties in the ways that are described in this
          privacy statement.
        </p>
        <p className="mb-4">
          The Service Provider may disclose User Provided and Automatically
          Collected Information:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>
            as required by law, such as to comply with a subpoena, or similar
            legal process;
          </li>
          <li>
            when they believe in good faith that disclosure is necessary to
            protect their rights, protect your safety or the safety of others,
            investigate fraud, or respond to a government request;
          </li>
          <li>
            with their trusted services providers who work on their behalf, do
            not have an independent use of the information we disclose to them,
            and have agreed to adhere to the rules set forth in this privacy
            statement.
          </li>
        </ul>
        <h2 className="text-2xl sm:text-3xl font-bold mt-6 mb-4">
          Opt-Out Rights
        </h2>
        <p className="mb-4">
          You can stop all collection of information by the Application easily
          by uninstalling it. You may use the standard uninstall processes as
          may be available as part of your mobile device or via the mobile
          application marketplace or network.
        </p>
        <h2 className="text-2xl sm:text-3xl font-bold mt-6 mb-4">
          Data Retention Policy
        </h2>
        <p className="mb-4">
          The Service Provider will retain User Provided data for as long as you
          use the Application and for a reasonable time thereafter. If you'd
          like them to delete User Provided Data that you have provided via the
          Application, please contact them at dillontrembath@gmail.com and they
          will respond in a reasonable time.
        </p>
        <h2 className="text-2xl sm:text-3xl font-bold mt-6 mb-4">Children</h2>
        <p className="mb-4">
          The Service Provider does not use the Application to knowingly solicit
          data from or market to children under the age of 13.
        </p>
        <p className="mb-4">
          The Service Provider does not knowingly collect personally identifiable
          information from children. The Service Provider encourages all
          children to never submit any personally identifiable information
          through the Application and/or Services. The Service Provider
          encourages parents and legal guardians to monitor their children's
          Internet usage and to help enforce this Policy by instructing their
          children never to provide personally identifiable information through
          the Application and/or Services without their permission. If you have
          reason to believe that a child has provided personally identifiable
          information to the Service Provider through the Application and/or
          Services, please contact the Service Provider
          (dillontrembath@gmail.com) so that they will be able to take the
          necessary actions. You must also be at least 16 years of age to
          consent to the processing of your personally identifiable information
          in your country (in some countries we may allow your parent or
          guardian to do so on your behalf).
        </p>
        <h2 className="text-2xl sm:text-3xl font-bold mt-6 mb-4">Security</h2>
        <p className="mb-4">
          The Service Provider is concerned about safeguarding the
          confidentiality of your information. The Service Provider provides
          physical, electronic, and procedural safeguards to protect information
          the Service Provider processes and maintains.
        </p>
        <h2 className="text-2xl sm:text-3xl font-bold mt-6 mb-4">Changes</h2>
        <p className="mb-4">
          This Privacy Policy may be updated from time to time for any reason.
          The Service Provider will notify you of any changes to the Privacy
          Policy by updating this page with the new Privacy Policy. You are
          advised to consult this Privacy Policy regularly for any changes, as
          continued use is deemed approval of all changes.
        </p>
        <p className="mb-4">
          This privacy policy is effective as of 2024-09-27
        </p>
        <h2 className="text-2xl sm:text-3xl font-bold mt-6 mb-4">
          Your Consent
        </h2>
        <p className="mb-4">
          By using the Application, you are consenting to the processing of your
          information as set forth in this Privacy Policy now and as amended by
          us.
        </p>
        <h2 className="text-2xl sm:text-3xl font-bold mt-6 mb-4">Contact Us</h2>
        <p className="mb-4">
          If you have any questions regarding privacy while using the
          Application, or have questions about the practices, please contact the
          Service Provider via email at dillontrembath@gmail.com.
        </p>
      </div>
    </main>
  );
};

export default PrivacyPolicy;
